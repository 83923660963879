//Setup
// @import url(https://fonts.googleapis.com/css?family=Open+Sans); 

@mixin stagger-anim-delay($i, $initial-delay, $stagger-delay) {
  @while $i > 0 {
    &:nth-of-type(#{$i}) {
      animation-delay: $initial-delay + $stagger-delay * $i;
    }
    $i: $i - 1;
  }
}

// body {
//   margin-top: 20px;
//   font-family: "Open Sans", sans-serif;
//   color: #282828;
// }

.skills-ul{
    list-style-type: none;
    padding-inline-start: 0px;
}

.skills {
  width: 80%;
  max-width: 960px;
  height: 960px;
  margin: auto;

  position: relative;
}

.lines {
  height: 100%;
  position: relative;
}
.line {
  height: 100%;
  
  width: 2px;
  position: absolute;

  background: rgba(rgb(169, 176, 245), 0.6);

  &.l--0 {
    left: 0;
  }
  &.l--25 {
    left: 25%;
  }
  &.l--50 {
    left: 50%;
  }
  &.l--75 {
    left: 75%;
  }
  &.l--100 {
    left: calc(100% - 1px);
  }
}
.line__label {
  display: block;
  top:-20px;
  width: 100px;
  text-align: center;

  position: absolute;
  right: -50px;

  &.title {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.charts {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10;
}

.chart {
  margin: 30px 0 0;

  &:first-child {
    margin: 0;
  }
}

.chart__title {
  display: block;
  margin: 0 0 10px;

  font-weight: bold;

  opacity: 0;

  animation: 1s anim-lightspeed-in ease forwards;
}

.chart--horiz {
  overflow: hidden;
}

.chart__bar {
  $border-rad: 4px;
  height: 30px;
  margin-bottom: 10px;

  background: linear-gradient(to left, #03588C, #73A2BF);
  border-top-right-radius: $border-rad;
  border-bottom-right-radius: $border-rad;
  opacity: 0;

  animation: 1s anim-lightspeed-in ease forwards;

  .chart--dev,
  .chart--prod,
  .chart--design & {
    @include stagger-anim-delay(1, 0.3s, 0.2s);
  }
}

.chart__label {
  padding-left: 10px;
  line-height: 30px;
  color: white;
}

//Keyframes
@keyframes anim-lightspeed-in {
  0% {
    transform: translateX(-200%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
