a.nav-link {
    color: #807c7c;
    text-decoration: none;
    padding: 0px;
    text-shadow: 1px 1px 1px #000;
    transition: 1s all;
    margin: auto;
}


.mr-auto{
    margin-left: auto;
}

a.nav-link:hover {
    color: #dddbd8;
    transition: 1s color, 1s letter-spacing;
    letter-spacing: .1em;
}

nav a{
    padding: .5em;
}

@media only screen and (max-width: 768px) and (min-width: 450px) {
    img.signature {
        max-height: 90px;
        padding-left: 5.4em;
    }
    .nav-image{
        display: none;
    }
}

@media only screen and (max-width: 450px) {
  
    .nav-image{
        display: none;
        
    }
    nav > form > button{
        width: 45px;
    }
    .social{
        font-size: 80%;
    }
    nav{
        width: 100%;
        padding: 0 !important;
    }
}