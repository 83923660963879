a>li {
    cursor: pointer;
    list-style-type: none;
    z-index: 0;
}

ul.side-menu>li {
    cursor: pointer;
    width: 100px;
}

.side-menu {
    position: relative;
    right: 3em;
    top: 1em;
    padding-inline-start: 1%;
    list-style: none;
    font-weight: bold;
    margin: 0em;
    float: right;
    text-align: right;
    color: grey;
    font-weight: 800;
    font-size: 120%;
}

@media only screen and (max-width: 768px) and (min-width: 400px) {
    .side-menu {
        top: 1.4em;
    }
}

@media only screen and (max-width: 400px) {
    .side-menu {
        top: 1.4em;
        z-index: 3;
    }
}