ul>span {
    opacity: 0;
    transform: 2s all;
    margin-left: 5px;
    z-index: 0;
    float: right;
    position: absolute;
}

ul.app-list>li {
    cursor: pointer;
}

.app-list {
    position: absolute;
    top: 3em;
    padding-inline-start: 1%;
    list-style: none;
    font-weight: bold;
    margin: 2em;
    font-weight: 800;
    font-size: 110%;
}

span.selected {
    color: #0099cc;
    opacity: 1;
    float: right !important;
    position: relative;
    transition: 1s opacity, 1s color;
}

li.selected {
    display: inline;
}
