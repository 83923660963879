section.myprofile-container {
    /* width: 44%; */
    margin: auto;
}

.profile-description {
    top: 2em;
    width: 30%;
}

.profile-main {
    top: 2em;
    left: 6em;
}

h1, h2 {
    text-align: right;
    font-family: 'Oswald', sans-serif;
    z-index: 50;
}


h1:after{
    content: "Hi I'm Jordi";
    transform: skew(-70deg,20deg) scale(.53,2) rotateZ(-5.1deg) rotateX(-60deg);
    text-shadow: 0 0 3px rgba(0, 0, 128, 0.25);
    color: rgba(69, 72, 221, 0);
    position: absolute;
	top: 0px;
    right: -25px;
    /* text-shadow: 0px -.5px 2px #b2a98f; */
    z-index: -1;
}

p {
    font-family: 'Lato', sans-serif;
    text-align: justify;
}

@media only screen and (max-width: 768px) and (min-width: 400px) {
    .profile-description {
        font-style: italic;
    }
    .profile-main {
        top: 4em;
        left: 0em;
    }
}

.portfolio-link{
    text-align: center;
    margin-top: 60px;
}

@media only screen and (max-width: 400px){
    .profile-description {
        font-style: italic;
    }
    .profile-main {
        top: 4em;
        left: 0em;
    }
    .myprofile-container{
        margin-top: 60%;
    }
}

.portfolio-link{
    text-align: center;
    margin-top: 60px;
}
