body {
    margin: 0 auto;
    position: relative;
}

img.signature {
    transform: rotate(-10deg);
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    max-height: 140px;
    opacity: 1;
}

nav {
    z-index: 2;
}

.app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
}

.not_found{
    text-align: center;
    margin-top: 4rem;
}

@media only screen and (max-width: 575px) and (min-width: 400px) {
    img.signature {
        display: none;
     }
     .container-xs {
         margin-top:50%
     }
     .skills{
         margin-top: 25%;
     }
}

@media only screen and (max-width: 400px) {
    img.signature {
        display: none;
     }
     header{
         max-height: 400px;
     }
     .skills{
        margin-top: 45%;
        font-size: 70%;
    }
    .container-xs {
        margin-top: 50%
    }

}

