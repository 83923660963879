@mixin stagger-anim-delay($i, $initial-delay, $stagger-delay) {
  @while $i > 0 {
    &:nth-of-type(#{$i}) {
      animation-delay: $initial-delay + $stagger-delay * $i;
    }
    $i: $i - 1;
  }
}

.app-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}
.project {
  position: relative;
  display: block;
  margin: auto;
  z-index: 2;
  padding-top: 0.5em;
  animation: anim-appear-in 0.1s forwards;
  opacity: 0;

  @include stagger-anim-delay(27, 0.2s, 0.1s);
}

//Keyframes
@keyframes anim-appear-in {
  0% {
    transition: opacity;
    opacity: 0;
  }
  100% {
    transition: opacity;
    opacity: 1;
  }
}

button.project-delete {
  display: block;
  margin: auto;
  width: 70%;
}

.project-edit {
  display: block;
  margin: auto;
  max-width: 70%;
  max-height: 70%;
  height: 2.2rem;
  text-align: center;
  margin: auto;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.project-edit:hover {
  color: white;
  background-color: #0069d9;
  border-color: #0062cc;
}

img.project {
  max-height: 70%;
  max-width: 70%;
  border-radius: 5%;
}

.caption-content {
  text-align: center;
}

#open-modal {
  cursor: pointer;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: cornflowerblue;
}

.caption {
  position: absolute;
  width: calc(100% - 0px);
  height: calc(100% - 80px);
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background: rgba(33, 107, 180, 0.7);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  max-width: 100%;
  z-index: 3;
}

.caption:hover {
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0.2;
}

.pulse:hover {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(0.5, 0.5, 0.5);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
